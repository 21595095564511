import { Center } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from '@chakra-ui/react';
import React from 'react';

import PrintQRCode from '.';

export const DownloadQRModal: React.FC<any> = ({ showModal, closeModal, codeUrl }) => {
	const callback = () => {
		closeModal(false);
	};
	return (
		<Center>
			<Modal isOpen={showModal} onClose={() => closeModal(false)}>
				<ModalContent maxWidth="300px">
					<ModalCloseButton />
					<ModalHeader>QR Code</ModalHeader>
					<ModalBody>
						<PrintQRCode codeUrl={codeUrl} callback={callback} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Center>
	);
};

export default DownloadQRModal;
