import { Box } from '@chakra-ui/react';
import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { BorderButton } from '@theark/react-common';

const PrintQRCode: React.FC<any> = ({ codeUrl, callback }) => {
	const onDownloadQRCode = () => {
		const canvas: any = document.querySelector('.QRCode > canvas');
		const href = canvas.toDataURL('image/png');
		const link = document.createElement('a');
		link.href = href;
		link.download = 'QRCode.png';
		const event = new MouseEvent('click');
		link.dispatchEvent(event);
		callback();
	};

	return (
		<>
			<Box as="div" w={170} mb={5} onClick={onDownloadQRCode}>
				<BorderButton isLarge={false}>Download QR Code</BorderButton>
			</Box>
			<Box as="div" className="QRCode">
				<QRCodeCanvas value={codeUrl} />
			</Box>
		</>
	);
};

export default PrintQRCode;
