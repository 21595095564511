import React, { useEffect, useState } from 'react';
import { Avatar, Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import axios from 'axios';
import { BorderButton } from '@theark/react-common';
import { getUserInfoByWalletAddresses } from '../../store/user/actions';
import { RenderNftAsset } from '../RenderNftAsset';
import { WishList } from '../Wishlist';
import { ellipsisText, imageURL } from '@theark/react-common';
import { PriceCard } from '../PriceCard';
import DownloadQRModal from '../QRCodeCanvas/DownloadQRModal';
import { IoQrCode } from 'react-icons/io5';
import Link from 'next/link';

interface NFTCard {
	item: any;
	cardWidth?: number;
	isAvatar?: boolean;
	descriptionLength?: number;
	callback?(e: any): any;
	isCreator?: boolean;
	isDollar?: boolean;
	isImageFullHeight?: boolean;
	isNonBorderButton?: boolean;
	showDescription?: boolean;
	tokenId: string;
	isDownloadQR?: boolean;
	QRCodeURL?: string;
}

export const Card: React.FC<NFTCard> = ({
	item,
	isAvatar,
	descriptionLength = 40,
	isCreator,
	isDollar,
	isImageFullHeight,
	isNonBorderButton,
	showDescription = false,
	tokenId,
	isDownloadQR = false,
	QRCodeURL = '',
}) => {
	const [creator, setCreator] = useState('');
	const [nftAsset, setNftAsset] = useState<string | undefined>();
	const fallbackIcon: string = item.icon ? imageURL(item.icon) : `https://picsum.photos/300/200.webp`;
	const [downloadQR, setDownloadQR] = useState(false);

	const loadAnimation = async () => {
		if (item.cover || item.image_url) return;
		await axios.get(`/api/nft/collection?tokenID=${tokenId}`).then((res) => {
			setNftAsset(res.data.metadata.animation_url);
		});
	};

	useEffect(() => {
		if (isCreator && item?.userID) {
			(async () => {
				const creatorName = await getUserInfoByWalletAddresses(item?.userID);
				if (creatorName) setCreator(creatorName);
			})();
		}
	}, [item?.userID, isCreator]);

	useEffect(() => {
		void loadAnimation();
	}, []);

	return (
		<>
			<DownloadQRModal codeUrl={QRCodeURL} showModal={downloadQR} closeModal={setDownloadQR} />
			<Flex
				flexDirection={'column'}
				justifyContent={'space-between'}
				bg={'cardBackground'}
				borderWidth={1}
				rounded={'md'}
				overflow={'hidden'}
				h={'100%'}
			>
				<Box>
					<RenderNftAsset
						image_url={item.cover || item.image_url}
						animation_url={nftAsset}
						isImageFullHeight={isImageFullHeight}
						imageAlt={item.imageAlt}
						key={tokenId}
					/>
					{isAvatar && (
						<Flex pl={5} pr={5} mt={-12} justifyContent={'space-between'} alignItems={'center'}>
							<Box>
								<Avatar size={'xl'} src={fallbackIcon} css={{ border: '2px solid white' }} />
							</Box>
						</Flex>
					)}
					<Flex pl={5} pr={5} pb={3} pt={3} justifyContent={'space-between'}>
						<Box mb={5}>
							<Heading fontSize={{ base: '12', md: '15' }} fontWeight={700}>
								{item.name}
							</Heading>
							{isCreator && <Text fontSize={{ base: '10', md: '12' }}>by Creator {creator}</Text>}
						</Box>
						<Flex alignItems={'center'}>
							<Box mr={2}>
								<WishList tokenID={tokenId} />
							</Box>
							{isDownloadQR ? (
								<Box>
									<IconButton
										aria-label="QR code"
										onClick={() => {
											setDownloadQR(true);
										}}
										padding={0}
										margin={0}
									>
										<IoQrCode size={15} color={'white'} />
									</IconButton>
								</Box>
							) : null}
						</Flex>
					</Flex>

					<Box as="div" pl={5} pr={5} pb={3}>
						{item.rawAmount && !showDescription && <PriceCard amount={item.rawAmount} showDollar={isDollar} />}
						{showDescription && (
							<Flex alignItems={'center'} justifyContent={'space-between'}>
								<Box>
									<Heading height={'30px'} fontSize={{ base: '10', md: '12' }}>
										{ellipsisText(item.description, descriptionLength)}
									</Heading>
								</Box>
							</Flex>
						)}
					</Box>
				</Box>
				<Box as="div" pl={5} pr={5} pb={3} mt={5}>
					<Link href={`/nft/${tokenId}`}>
						<BorderButton isNonBorderButton={isNonBorderButton} isLarge={false}>
							{item.buttonTxt ? item.buttonTxt : 'View NFT'}
						</BorderButton>
					</Link>
					{/* <BorderButton isNonBorderButton={isNonBorderButton} isLarge={false} callback={callback}>
						{item.buttonTxt ? item.buttonTxt : 'View NFT'}
					</BorderButton> */}
				</Box>
			</Flex>
		</>
	);
};

export default Card;
