import { Box, Flex, Heading, Stat, StatHelpText } from '@chakra-ui/react';
import axios from 'axios';
import { ethers } from 'ethers';
import { FC, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

interface PriceCardProps {
	amount: string;
	showDollar?: boolean;
	currency?: string;
}

interface EthPrice {
	price?: {
		[currency: string]: number;
	};
	expiry?: number;
}

export const PriceCard: FC<PriceCardProps> = ({ amount, showDollar = false, currency = 'USD' }) => {
	const ethAmount = amount.includes('ETH') ? amount.replace(' ETH', '') : amount;
	const [ethPrice, setEthPrice] = useLocalStorage<EthPrice>('ETH_PRICE', {});
	const [dollarPrice, setDollarPrice] = useState<string>();

	useEffect(() => {
		if (ethPrice.price && showDollar) {
			setDollarPrice((Number.parseFloat(ethers.utils.formatEther(ethAmount)) * ethPrice.price[currency]).toFixed(2));
		}
	}, [ethPrice, showDollar]);

	useEffect(() => {
		(async () => {
			if (showDollar) {
				if (!ethPrice.price || !ethPrice.expiry || ethPrice.expiry < Date.now()) {
					const res = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=${currency}`);
					setEthPrice({ price: res.data, expiry: new Date(Date.now() + 600000).getTime() });
				}
			}
		})();
	}, [ethPrice, showDollar]);

	if (!ethAmount) return <></>;

	return (
		<Flex alignItems={'center'} justifyContent={'space-between'}>
			<Box>
				<Heading fontSize={{ base: '20', md: '30' }} fontWeight={800}>
					{ethers.utils.formatEther(ethAmount)} ETH
				</Heading>
			</Box>

			{showDollar && (
				<Box>
					<Stat>
						<StatHelpText float="left" fontSize={{ base: '10', md: '12' }} mt="3" ml="2">
							(${dollarPrice} {currency})
						</StatHelpText>
					</Stat>
				</Box>
			)}
		</Flex>
	);
};
