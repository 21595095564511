import { Icon, IconProps, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback } from 'react';
import { FC, useEffect, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useLocalStorage } from 'usehooks-ts';
import { getTokenHeader } from '@theark/react-common';
import { useArkContext } from '../../contexts';

interface WishListProps extends IconProps {
	tokenID: string;
}

interface WishListData {
	items: number[];
	expiry?: number;
}

export const WishListNFT: FC<WishListProps> = ({ tokenID, ...rest }) => {
	const {
		state: { ark: arkState },
	} = useArkContext();
	const toast = useToast();
	const wishListApi = '/api/wishlist';
	const [wishListData, setWishListData] = useLocalStorage<WishListData>('WISHLIST_DATA', { items: [] });
	const isInWishList = wishListData.items.includes(parseInt(tokenID));
	const [isWishListLoading, setIsWishListLoading] = useState<boolean>(false);

	const loadWishlist = useCallback(async () => {
		setIsWishListLoading(true);
		const response = await axios.get(wishListApi, { headers: getTokenHeader(arkState.user.accessToken ?? '') });
		setWishListData({ items: response.data.metadata, expiry: new Date(Date.now() + 600000).getTime() });
	}, [arkState.user]);

	useEffect(() => {
		if (!wishListData.expiry || wishListData.expiry < Date.now()) {
			if (arkState.user && arkState.user.accessToken) {
				loadWishlist().then(() => {
					setIsWishListLoading(false);
				});
			}
		}
	}, [arkState.user?.accessToken]);

	const handleWishlist = async () => {
		if (isInWishList) {
			axios({
				url: wishListApi,
				data: { nftID: tokenID },
				method: 'DELETE',
				headers: getTokenHeader(arkState.user?.accessToken ?? ''),
			}).then(() => {
				setWishListData({
					items: wishListData.items.filter((item) => item !== parseInt(tokenID)),
					expiry: new Date(Date.now() + 600000).getTime(),
				});
				toast({
					title: 'Removed from favorites',
					status: 'success',
					variant: 'arkSuccess',
					position: 'bottom-right',
					duration: 2000,
				});
			});
		} else {
			axios({
				url: wishListApi,
				data: { nftID: tokenID },
				method: 'POST',
				headers: getTokenHeader(arkState.user?.accessToken ?? ''),
			}).then(() => {
				setWishListData({ items: [...wishListData.items, parseInt(tokenID)], expiry: new Date(Date.now() + 600000).getTime() });
				toast({
					title: 'Added to favorites',
					status: 'success',
					variant: 'arkSuccess',
					position: 'bottom-right',
					duration: 2000,
				});
			});
		}
	};

	if (!arkState.user?.accessToken || isWishListLoading) return <></>;

	if (isInWishList) return <Icon as={AiFillHeart} onClick={handleWishlist} color="teal.300" cursor="pointer" {...rest} />;

	return <Icon as={AiOutlineHeart} onClick={handleWishlist} color="teal.300" cursor="pointer" />;
};
